import React, { useContext, useEffect, useState } from 'react'
import classes from './ReplicaDetail.module.scss'
import axios from 'axios'
import config from '../../../../config/config'
import ReplicaApprove from '../ReplicaApprove/ReplicaApprove'
import ReplicaReject from '../ReplicaReject/ReplicaReject'
import Input from '../../../UI/Input/Input'
import { PopupContext } from '../../../../context/popup/popupContext'
import ReplicaHistory from '../ReplicaAdd/ReplicaHistory/ReplicaHistory'

const statusRollback = {
    'for-posting': {
        prevCode: 'waiting-agreement',
        prevName: 'Ожидает согласования'
    },

    'under-moderation': {
        prevCode: 'posting',
        prevName: 'На размещении'
    },

    posted: {
        prevCode: 'moderation',
        prevName: 'Отправлено на модерацию'
    }
}

const ReplicaDetail = (props) => {
    const popupContextData = useContext(PopupContext)
    const [showCompleteFakeBtn, setCompleteFake] = useState(props.status.code === 'for-posting' &&
        (props.accessLevel === 1 ||  props.accessLevel === 4))


    const [showComplete1, setComplete1] = useState(props.status.code === 'under-moderation' &&
        (props.accessLevel === 1 || props.accessLevel === 4))



    const [showScreenshotAndAgentName, setShowScreenshotAndAgentName] = useState(props.status.code === 'under-moderation' &&
        (props.accessLevel === 1 || props.accessLevel === 4 || props.accessLevel === 5))


    const [screenshot, setScreenshot] = useState(props.screenshot ? props.screenshot : '')
    const [errorScreenshot, setScreenshotError] = useState(null);
    const [agentName, setAgentName] = useState(props.agentName ? props.agentName : '')
    const [errorAgent, setAgentError] = useState(null);

    const [noteSystem, setNoteSystem] = useState(props.noteSystem ? props.noteSystem : '')

    const dateFormat = new Intl.DateTimeFormat('ru', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    })

    const [msg, setMsg] = useState({})
    const [formReject, setFormReject] = useState(false)
    const [formApprove, setFormApprove] = useState(false)
    const [replicaHistory, setReplicaHistory] = useState([])
    const [rollbackStatus, setrollbackStatus] = useState(null)

    const getMsg = async () => {
        try {
            if (props.id) {
                // popupContextData.setPreloader(true)

                const token = localStorage.getItem('token')
                const response = await axios.get(`${config.SERVER_BASE_URL}/replica/msg/${props.id}?token=${token}`)
                const responseData = response.data

                if (responseData.status === 'fail') {
                    alert(responseData.errorText)
                } else {
                    setMsg(responseData.data)
                    setReplicaHistory(response.data.editHistory)
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            popupContextData.setPreloader(false);
        }
    }

    const changeStatus = async (status) => {
        popupContextData.setPreloader(true)

        const token = localStorage.getItem('token')
        const response = await axios.get(`${config.SERVER_BASE_URL}/replica/status/${status}/${props.id}?token=${token}`)
        const responseData = response.data

        if (responseData.status === 'fail') {
            popupContextData.setPreloader(false)
            alert(responseData.errorText)
        } else {
            popupContextData.setPreloader(false)
            props.onSuccesUpdateRepica(props.id, responseData.data);
            props.close()
        }
    }

    const screenshotOpen = () => {
        setShowScreenshotAndAgentName(true)
        setComplete1(true)
        setCompleteFake(false)
    }

    const screenshotUpdate = async () => {
        // popupContextData.setPreloader(true)
        const token = localStorage.getItem('token')

        await axios.post(`${config.SERVER_BASE_URL}/replica/screenshot/${props.id}`, {
            token,
            screenshot
        })

        props.replicaChange(props.id, 'screenshot', screenshot)
        // popupContextData.setPreloader(false)
        // props.updateReplicaList()
    }

    const agentNameUpdate = async () => {
        // popupContextData.setPreloader(true)
        const token = localStorage.getItem('token')

        await axios.post(`${config.SERVER_BASE_URL}/replica/agent-name/${props.id}`, {
            token,
            agentName
        })

        props.replicaChange(props.id, 'agentName', agentName)
        // popupContextData.setPreloader(false)
        // props.updateReplicaList()
    }

    const noteSystemUpdate = async () => {
        // popupContextData.setPreloader(true)
        const token = localStorage.getItem('token')

        await axios.put(`${config.SERVER_BASE_URL}/replica/noteSystem/${props.id}`, {
            token,
            text: noteSystem
        })

        props.replicaChange(props.id, 'noteSystem', noteSystem)
        // popupContextData.setPreloader(false)
        // props.updateReplicaList()
    }

    const replicaCopy = async () => {
        await navigator.clipboard.writeText(props.msg)
        alert('Реплика скопирована и находится в буфере обмена')
    }

    const showApproveBtn = props.status.code === 'waiting-agreement' &&
        (props.accessLevel === 1 || props.accessLevel === 2 || props.accessLevel === 5);

    const showRollbackBtn = (props.status.code === 'posted' || props.status.code === 'for-posting' || props.status.code === 'under-moderation') &&
        props.accessLevel !== 2;

    const showRejectBtn = props.status.code === 'waiting-agreement' &&
        (props.accessLevel === 1 || props.accessLevel === 2 || props.accessLevel === 5);

    const showEditBtn = (props.status.code === 'created' || props.status.code === 'waiting-agreement' || props.status.code === 'refused') &&
        (props.accessLevel === 1 || props.accessLevel === 2 || props.accessLevel === 4 || props.accessLevel === 5) &&
        props.onlyView;

    const showDeleteBtn = (props.status.code === 'created' || props.status.code === 'waiting-agreement' || props.status.code === 'refused') &&
        (props.accessLevel === 1 || props.accessLevel === 4 || props.accessLevel === 5)

    const showCopyBtn =  props.status.code === 'for-posting' &&
        (props.accessLevel === 1 || props.accessLevel === 4 || props.accessLevel === 5)

    const showSendBtn =  props.status.code === 'for-posting' &&
        (props.accessLevel === 1 || props.accessLevel === 4 || props.accessLevel === 5)


    const showNoteSystemBtn =  props.status.code !== 'waiting-agreement' &&
        (props.accessLevel === 1 || props.accessLevel === 4 || props.accessLevel === 5)



    const onCancelNewReplica = async (id) => {
        try {
            popupContextData.setPreloader(true)
            const token = localStorage.getItem('token')

            const { data } = await axios.post(`${config.SERVER_BASE_URL}/replica/history-repair/${id}`, {
                token
            })

            if (data.status === 'success') {
                // props.updateReplicaList()
                props.close()
            }

            popupContextData.setPreloader(false)
        } catch (error) {
            popupContextData.setPreloader(false)
        }
    }

    const onChangeRollbackStatus = (status) => {
        setrollbackStatus(statusRollback[status])
    }

    const onRollbackStatus = () => {
        changeStatus(rollbackStatus?.prevCode)
        setrollbackStatus(null)
    }

    useEffect(() => {
        (async () => {
            await getMsg();
        })();
    }, []);

    return (
        <>
            <div className='replic__replic-unit-ticket'>
                {showScreenshotAndAgentName ? (
                    <div className='replic__replic-unit-ticket-screen-shot-add'>
                        <div className='replic__replic-unit-ticket-screen-shot-text'>Скриншот</div>

                        <div className='replic__replic-unit-ticket-screen-shot-input'>
                            <Input
                                type='text'
                                name='screenshot'
                                value={screenshot}
                                onChange={(event) => {
                                    setScreenshot(event.target.value)
                                    if (errorScreenshot != null)  setScreenshotError(null)
                                }}
                                onBlur={screenshotUpdate}
                            />
                            {errorScreenshot && <div style={{ color: 'red', fontWeight: '500' }}>Заполните поле</div>}
                        </div>
                    </div>
                ) : null}

                {showScreenshotAndAgentName ? (
                    <div className='replic__replic-unit-ticket-screen-shot-add'>
                        <div className='replic__replic-unit-ticket-screen-shot-text'>Имя агента</div>

                        <div className='replic__replic-unit-ticket-screen-shot-input'>
                            <Input
                                type='text'
                                name='agentName'
                                value={agentName}
                                onChange={(event) => {
                                    setAgentName(event.target.value)
                                    if (errorAgent != null)  setAgentError(null)
                                }}
                                onBlur={agentNameUpdate}
                            />
                            {errorAgent && <div style={{ color: 'red', fontWeight: '500' }}>Заполните поле</div>}

                        </div>
                    </div>
                ) : null}

                {showNoteSystemBtn ? (
                    <div className='replic__replic-unit-ticket-screen-shot-add'>
                        <div className={classes.NoteSystem + ' replic__replic-unit-ticket-screen-shot-text '}>
                            Примечание
                            <span>A</span>
                        </div>

                        <div className='replic__replic-unit-ticket-screen-shot-input  noteSystem'>
                            <Input
                                type='text'
                                name='noteSystem'
                                value={noteSystem}
                                onChange={(event) => setNoteSystem(event.target.value)}
                                onBlur={noteSystemUpdate}
                            />
                        </div>
                    </div>
                ) : null}


                {(showApproveBtn ||
                    showRejectBtn ||
                    showEditBtn ||
                    showDeleteBtn ||
                    showCopyBtn ||
                    showSendBtn ||
                    showRollbackBtn ||
                    showComplete1) &&
                !rollbackStatus && (props.isOpenedReplic || props.isOpenDetail) ? (
                    <div className='replic__replic-unit-ticket-buttons'>
                        {showApproveBtn ? (
                            <div className='replic__replic-unit-ticket-button replic__replic-unit-ticket-button_green'>
                                <button
                                    onClick={() => {
                                        setFormApprove(true)
                                        setFormReject(false)
                                    }}
                                >
                                    Одобрить
                                </button>
                            </div>
                        ) : null}

                        {showRejectBtn ? (
                            <div className='replic__replic-unit-ticket-button replic__replic-unit-ticket-button_red'>
                                <button
                                    onClick={() => {
                                        setFormApprove(false)
                                        setFormReject(true)
                                    }}
                                >
                                    Отклонить
                                </button>
                            </div>
                        ) : null}

                        {showEditBtn ? (
                            <div className='replic__replic-unit-ticket-button replic__replic-unit-ticket-button_yellow'>
                                <button onClick={props.onEdit}>Редактирование</button>
                            </div>
                        ) : null}

                        {showCopyBtn ? (
                            <div className='replic__replic-unit-manage-button replic__replic-unit-manage-button_blue'>
                                <button onClick={replicaCopy}>Скопировать реплику</button>
                            </div>
                        ) : null}

                        {showSendBtn ? (
                            <div className='replic__replic-unit-manage-button replic__replic-unit-manage-button_light-blue'>
                                <button onClick={() => changeStatus('moderation')}>Отправить на модерацию</button>
                            </div>
                        ) : null}

                        {showComplete1 ? (
                            <div className='replic__replic-unit-manage-button replic__replic-unit-manage-button_gray'>
                                <button onClick={() => {
                                    if ((screenshot.length > 0 && screenshot !== '') && (agentName.length > 0 && agentName !== '' )) {
                                        changeStatus('posted')
                                    } else {

                                        if (screenshot.length === 0 || screenshot === '') {
                                            setScreenshotError("Заполните поле")
                                        }

                                        if (agentName.length === 0 || agentName === '') {
                                            setAgentError("Заполните поле")
                                        }

                                    }
                                }}>Размещено</button>
                            </div>
                        ) : null}

                        {showCompleteFakeBtn ? (
                            <div className='replic__replic-unit-manage-button replic__replic-unit-manage-button_gray'>
                                <button onClick={() => screenshotOpen()}>Размещено</button>
                            </div>
                        ) : null}

                        {showRollbackBtn ? (
                            <div className='replic__replic-unit-ticket-button replic__replic-unit-ticket-button_yellow'>
                                <button onClick={() => onChangeRollbackStatus(props.status.code)}>Откатить статус</button>
                            </div>
                        ) : null}

                        {showDeleteBtn ? (
                            <div className='replic__replic-unit-ticket-button replic__replic-unit-ticket-button_purple'>
                                <button onClick={props.onDelete}>Удалить</button>
                            </div>
                        ) : null}
                    </div>
                ) : null}

                {rollbackStatus && (
                    <div className={classes.ControlRollbackStatus}>
                        <div className={classes.ControlRollbackStatusText}>
                            Вы действительно хотите изменить статус реплики с {`"${props.status.name}"`} на{' '}
                            {`"${rollbackStatus.prevName}"`}
                        </div>

                        <div className={classes.ControlRollbackStatusBtns}>
                            <button onClick={onRollbackStatus}>да</button>
                            <button onClick={() => setrollbackStatus(null)}>нет</button>
                        </div>
                    </div>
                )}

                {formApprove ? (
                    <ReplicaApprove
                        id={props.id}
                        onSuccesUpdateRepica={props.onSuccesUpdateRepica}
                        getMsg={getMsg}
                        close={() => {
                            setFormApprove(false)
                            props.close()
                        }}
                    />
                ) : null}

                {formReject ? (
                    <ReplicaReject
                        id={props.id}
                        getMsg={getMsg}
                        onRejectReplica={props.onRejectReplica}
                        close={() => {
                            setFormReject(false)
                            props.close()
                        }}
                    />
                ) : null}

                {Object.keys(msg).length
                    ? Object.keys(msg).map((msgId, index) => {
                          const msgItem = msg[msgId]

                          return (
                              <div
                                  className='replic__replic-unit-ticket-item'
                                  key={index}
                                  data-status={msgItem.status && msgItem.status.code ? msgItem.status.code : null}
                              >
                                  <div className='replic__replic-unit-ticket-item-left-side'>
                                      {msgItem.comment ? (
                                          <div className='replic__replic-unit-ticket-item-left-side-text'>
                                              Комментарий
                                              <br /> клиента
                                          </div>
                                      ) : null}
                                  </div>

                                  <div className='replic__replic-unit-ticket-item-right-side'>
                                      <div className='replic__replic-unit-ticket-client-comment'>
                                          {msgItem.comment ? (
                                              <>
                                                  {msgItem.modifiedDate ? (
                                                      <div className='replic__replic-unit-ticket-client-comment-date'>
                                                          {dateFormat.format(new Date(msgItem.modifiedDate))}
                                                      </div>
                                                  ) : null}
                                                  <div className='replic__replic-unit-ticket-client-comment-type-mobile replic__replic-unit-ticket-client-comment-type-mobile_red'>
                                                      Комментарии клиента
                                                  </div>
                                                  <div className='replic__replic-unit-ticket-client-comment-replic'>
                                                      {msgItem.comment}
                                                  </div>
                                              </>
                                          ) : null}

                                          <div className='replic__replic-unit-ticket-client-comment-comment'>
                                              <div className='replic__replic-unit-ticket-client-comment-comment-target'>
                                                  {msgItem.comment ? (
                                                      <div className='replic__replic-unit-ticket-client-comment-comment-target-text'>
                                                          К реплике от
                                                      </div>
                                                  ) : null}
                                                  {msgItem.createdDate ? (
                                                      <div className='replic__replic-unit-ticket-client-comment-comment-target-date'>
                                                          {dateFormat.format(new Date(msgItem.createdDate))}
                                                      </div>
                                                  ) : null}
                                              </div>

                                              <div className='replic__replic-unit-ticket-client-comment-comment-text'>
                                                  {msgItem.msg}
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          )
                      })
                    : null}

                {props.fields.length
                    ? props.fields.map((field, index) => {
                          const fieldValue = field.value
                              ? field.value
                              : field.valueOptions && field.valueOptions.value
                              ? field.valueOptions.value
                              : ''

                          if (!field.field.name && field.field.type === 'url') {
                              field.field.name = 'URL'
                          }

                          return (
                              <div className='replic__replic-unit-ticket-item' key={index}>
                                  <div className='replic__replic-unit-ticket-item-left-side replic__replic-unit-ticket-item-right-side_center'>
                                      <div className='replic__replic-unit-ticket-item-left-side-text'>
                                          {field.field.type === 'note' ? 'Примечание' : field.field.name}
                                      </div>
                                  </div>

                                  <div className='replic__replic-unit-ticket-item-right-side'>
                                      <div className='replic__replic-unit-ticket-item-info'>
                                          {field.createdDate ? (
                                              <div className='replic__replic-unit-ticket-item-info-date'>
                                                  {dateFormat.format(new Date(field.createdDate))}
                                              </div>
                                          ) : null}
                                          <div className='replic__replic-unit-ticket-client-comment-type-mobile'>
                                              {field.field.name}
                                          </div>

                                          <div className='replic__replic-unit-ticket-item-info-text'>
                                              {field.field.type === 'url' ? (
                                                  <a href={fieldValue} target='_blank' rel='noopener noreferrer'>
                                                      {fieldValue}
                                                  </a>
                                              ) : (
                                                  fieldValue
                                              )}
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          )
                      })
                    : null}
            </div>

            {(props.isOpenedReplic || props.isOpenDetail) && replicaHistory.length > 0 && (
                <ReplicaHistory
                    items={replicaHistory}
                    status={props.status}
                    isOpenedReplic={props.isOpenedReplic}
                    onCancelNewReplica={onCancelNewReplica}
                    accessLevel={props.accessLevel}
                />
            )}
        </>
    )
}

export default ReplicaDetail
