import axios from 'axios'
import config from '../config/config'
import { getErrorData } from './errors'

export const apiUserList = async (id, type, filter = {}) => {
    const users = []
    const token = localStorage.getItem('token')
    const filterData = []

    Object.keys(filter).map((filterId) => {
        const filterItem = filter[filterId]

        if (filterItem.name && filterItem.value) {
            return filterData.push(`f-${filterItem.name}=${filterItem.value}`)
        } else {
            return true
        }
    })

    let paramName = false

    if (type === 'company') {
        paramName = 'companyId'
    } else {
        paramName = 'roleId'
    }

    const response = await axios.get(
        `${config.SERVER_BASE_URL}/users?token=${token}&${paramName}=${id}${filterData.length ? '&' + filterData.join('&') : ''}`
    )

    if (response && response.data.status === 'success') {
        response.data.data.map((user) => {
            return (users[user._id] = {
                ...user
            })
        })
    }

    if (Object.keys(users).length > 0) {
        return {
            status: 'success',
            data: users
        }
    } else {
        return getErrorData()
    }
}

export const apiUserAdd = async (id, type, inputs, onlyView) => {
    const token = localStorage.getItem('token')
    const errors = []

    const data = {
        token,
        onlyView
    }

    if (type === 'company') {
        data.companyId = id
    } else {
        data.role = id
    }

    Object.keys(inputs).map((inputId) => {
        const input = inputs[inputId]
        return (data[input.name] = input.value)
    })

    if (!data.name) {
        errors.push('Заполните поле "Имя"')
    }

    if (!data.lastName) {
        errors.push('Заполните поле "Фамилия"')
    }

    if (!data.email) {
        errors.push('Заполните поле "Электронная почта"')
    } else {
        const re =
            /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

        if (!re.test(String(data.email).toLowerCase())) {
            errors.push('Электронная почта указана некорректно')
        }
    }

    if (errors.length) {
        return getErrorData(errors)
    } else {
        const response = await axios.post(`${config.SERVER_BASE_URL}/users`, data)

        if (response && response.data.status === 'success') {
            return {
                status: 'success'
            }
        } else {
            return getErrorData(response.data.errorText)
        }
    }
}

export const apiUserEdit = async (userId, inputs, onlyView, result) => {
    const token = localStorage.getItem('token')
    const errors = []

    const data = {
        ...result,
        token,
        onlyView,
    }

    Object.keys(inputs).map((inputId) => {
        const input = inputs[inputId]
        return (data[input.name] = input.value)
    })

    if (!data.name) {
        errors.push('Заполните поле "Имя"')
    }

    if (!data.lastName) {
        errors.push('Заполните поле "Фамилия"')
    }

    if (!data.email) {
        errors.push('Заполните поле "Электронная почта"')
    } else {
        const re =
            /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

        if (!re.test(String(data.email).toLowerCase())) {
            errors.push('Электронная почта указана некорректно')
        }
    }

    if (!userId) {
        errors.push('ID пользователя не найден')
    }

    if (errors.length) {
        return getErrorData(errors)
    } else {
        const response = await axios.put(`${config.SERVER_BASE_URL}/users/${userId}`, data)

        if (response && response.data.status === 'success') {
            return {
                status: 'success'
            }
        } else {
            return getErrorData(response.data.errorText)
        }
    }
}

export const apiUserDelete = async (userId) => {
    const token = localStorage.getItem('token')
    const errors = []

    if (!userId) {
        errors.push('ID пользователя не найден')
    }

    if (errors.length) {
        return getErrorData(errors)
    } else {
        const response = await axios.delete(`${config.SERVER_BASE_URL}/users/${userId}?token=${token}`)

        if (response && response.data.status === 'success') {
            return {
                status: 'success'
            }
        } else {
            return getErrorData(response.data.errorText)
        }
    }
}

export const apiUserLogin = async (inputs) => {
    const errors = []
    const data = {}

    Object.keys(inputs).map((inputId) => {
        const input = inputs[inputId]
        return (data[input.name] = input.value)
    })

    if (!data.email) {
        errors.push('Заполните поле "Электронная почта"')
    }

    if (!data.password) {
        errors.push('Заполните поле "Пароль"')
    }

    if (errors.length) {
        return getErrorData(errors)
    } else {
        const response = await axios.post(`${config.SERVER_BASE_URL}/users/login`, data)

        if (response && response.data.status === 'success') {
            return {
                status: 'success',
                token: response.data.data.token,
                tokenExp: response.data.data.tokenExp,
                accessLevel: response.data.data.accessLevel,
                id: response.data.data.id,
            }
        } else {
            return getErrorData(response.data.errorText)
        }
    }
}

export const apiUserCheckToken = async (token) => {
    if (token) {
        const response = await axios.get(`${config.SERVER_BASE_URL}/users/checkToken/${token}`)

        if (response && response.data.status === 'success') {
            return {
                status: 'success'
            }
        } else {
            return getErrorData(response.data.errorText)
        }
    } else {
        return getErrorData('error')
    }
}
