import React, { useState, useEffect } from 'react'

function generatorId(min, max) {
    return Math.floor(Math.random() * (max - min) + min)
}

export const useDesktopPlan = (list, onSavePlan, onSavePlanForDeleteInnerRow) => {
    const [items, setItems] = useState([])

    const prevDateEnd = items.length > 1 ? items[items.length - 2].dateEnd : null
    const isDisabledAddRow = !!(!items[items.length - 1]?.dateStart && !items[items.length - 1]?.dateEnd)

    const defaultRow = {
        id: generatorId(100, 999999),
        dateStart: '',
        dateEnd: '',
        remainsOld: 0,
        needed: 0,
        remains: 0,
        used: 0,
        agreed: 0,
        posted: 0,
        isNew: true,
        items: [
            {
                id: generatorId(100, 999999),
                name: { value: '', isEdit: false },
                newUrl: { value: '', isEdit: false },
                remainsOld: { value: 0, isEdit: false },
                needed: { value: 0, isEdit: false },
                remains: { value: 0, isEdit: false },
                used: { value: 0, isEdit: false },
                agreed: { value: 0, isEdit: false },
                posted: { value: 0, isEdit: false }
            }
        ]
    }

    const defaultInnerRow = {
        id: generatorId(100, 999999),
        name: { value: '', isEdit: false },
        newUrl: { value: '', isEdit: false },
        remainsOld: { value: 0, isEdit: false },
        needed: { value: 0, isEdit: false },
        remains: { value: 0, isEdit: false },
        used: { value: 0, isEdit: false },
        agreed: { value: 0, isEdit: false },
        posted: { value: 0, isEdit: false }
    }

    const onAddRow = () => {
        let newArr = [...items]

        newArr.push(defaultRow)
        setItems(newArr)
    }

    const onAddInnerRow = (rowId) => {
        const newArr = items.map((item) => {
            if (item.id === rowId) {
                return { ...item, items: [...item.items, defaultInnerRow] }
            }

            return item
        })

        setItems(newArr)
    }

    const onDeleteRow = (id, typePlan) => {
        let newArr = items.filter((item) => item.id !== id)

        setItems(newArr)
        onSavePlanForDeleteInnerRow(typePlan, newArr)
    }

    const onDeleteInnerRow = (rowId, rowInnerId, typePlan, isInnerLvlTwo, rowInnerLvlTwoId) => {
        let newArr = []

        if (isInnerLvlTwo) {
            newArr = items.map((item) => {
                if (item.id === rowId) {
                    const newSitesArr = item.items.map((site) => {
                        return {
                            ...site,
                            items: site.items.filter((itemInner) => {
                                return itemInner.id !== rowInnerLvlTwoId
                            })
                        }
                    })

                    return { ...item, items: newSitesArr }
                }

                return item
            })
        } else {
            newArr = items.map((item) => {
                if (item.id === rowId) {
                    let newSites = item.items.filter((site) => site.id !== rowInnerId)

                    if (!newSites.length) {
                        newSites.push(defaultInnerRow)
                    }

                    return { ...item, items: newSites }
                }

                return item
            })
        }

        setItems(newArr)
        // return
        onSavePlanForDeleteInnerRow(typePlan, newArr)
    }

    const onChangeFieldsInnerRow = (rowId, rowInnerId, nameField, valueField, isInnerLvlTwo, rowInnerLvlTwoId) => {
        const IS_NUMBER_TYPE = nameField === 'remainsOld' || nameField === 'needed' || nameField === 'remains'

        const newUrl = nameField === 'name' ? { value: valueField, isEdit: false } : { value: '', isEdit: false }

        let newArr = []

        if (isInnerLvlTwo) {
            newArr = items.map((item) => {
                if (item.id === rowId) {
                    const newSitesArr = item.items.map((site) => {
                        return {
                            ...site,
                            items: site.items.map((itemInner) => {
                                if (itemInner.id === rowInnerLvlTwoId) {
                                    return {
                                        ...itemInner,
                                        [nameField]: IS_NUMBER_TYPE
                                            ? { value: +valueField, isEdit: true }
                                            : { value: valueField, isEdit: true },
                                        newUrl
                                    }
                                }

                                return itemInner
                            })
                        }
                    })

                    return { ...item, items: newSitesArr }
                }

                return item
            })
        } else {
            newArr = items.map((item) => {
                if (item.id === rowId) {
                    const newSitesArr = item.items.map((site) => {
                        if (site.id === rowInnerId) {
                            return {
                                ...site,
                                [nameField]: IS_NUMBER_TYPE
                                    ? { value: +valueField, isEdit: true }
                                    : { value: valueField, isEdit: true },
                                newUrl
                            }
                        }

                        return site
                    })

                    return { ...item, items: newSitesArr }
                }

                return item
            })
        }

        setItems(newArr)
    }

    const onChangeFieldsRow = (rowId, nameField, valueField, typePlan) => {
        const newArr = items.map((item) => {
            if (item.id === rowId) {
                return { ...item, [nameField]: valueField }
            }

            return item
        })

        if (nameField === 'dateEnd') {
            onSavePlanForDeleteInnerRow(typePlan, newArr)
        }

        setItems(newArr)
    }

    const onChangeIsEdit = () => {
        const newArr = items.map((item) => {
            const newArrSites = item.items.map((site) => {
                const newItem = Object.entries(site).reduce((obj, [key, value]) => {
                    if (key !== 'id' && key !== 'items') {
                        obj[key] = { ...value, isEdit: false }
                    } else {
                        obj[key] = value
                    }

                    return obj
                }, {})

                return { ...newItem }
            })

            return { ...item, items: newArrSites }
        })

        setItems(newArr)
    }

    const onFormattingObj = (obj) => {
        const newItem = Object.entries(obj).reduce((obj, [key, value]) => {
            if (key !== 'id' && key !== 'items') {
                obj[key] = { value, isEdit: false }
            } else {
                obj[key] = value
            }

            return obj
        }, {})

        return { ...newItem, newUrl: { value: '', isEdit: false } }
    }

    const onCopyRow = (rowId) => {
        let copyObj = {}

        const newArr = items.map((item) => {
            if (item.id === rowId) {
                copyObj = { ...item, dateEnd: '', dateStart: '', id: generatorId(100, 999999) }
            }

            return item
        })

        setItems([...newArr, copyObj])
    }

    useEffect(() => {
        const getDateUTC = (date) => {
            const arr = date.split('.')
            const newDate = new Date(`${arr[2]}-${arr[1]}-${arr[0]}`)
            return newDate
        }

        if (list?.length) {
            const defaultList = list.map((item) => {
                const newArrSites = item.items.map((site) => {
                    const newItem = onFormattingObj(site)

                    let newItems = [...(site?.items || [])]

                    if (site?.items?.length) {
                        newItems = site?.items?.map((innerItem) => {
                            const newInnerItem = onFormattingObj(innerItem)

                            return { ...newInnerItem }
                        })
                    }

                    return {
                        ...newItem,
                        items: newItems
                    }
                })

                return { ...item, items: newArrSites, dateStart: getDateUTC(item.dateStart), dateEnd: getDateUTC(item.dateEnd) }
            })

            setItems(defaultList)
        }
    }, [list])

    return {
        items,
        onAddRow,
        onDeleteRow,
        onAddInnerRow,
        onDeleteInnerRow,
        onChangeFieldsInnerRow,
        onChangeFieldsRow,
        onChangeIsEdit,
        prevDateEnd,
        isDisabledAddRow,
        onCopyRow
    }
}
