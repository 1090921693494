import React from 'react';

import Input from '../../../components/UI/Input/Input';

import "./RatingInput.scss";

export const RatingInput = (props) => {
    const { disabled, onChange, value, onBlur } = props;

    return (
        <Input
            value={value}
            disabled={disabled}
            onChange={onChange}
            className="search-rating-input"
            onBlur={onBlur}
        />
    );
};
