import React, { Fragment, useState } from 'react'
import classes from '../DesktopPlan.module.scss'
import cn from 'classnames'
import { headerDataDesctop, headerDataTablet, headerDataInner } from '../headerData'
import { useWindowSize } from '../../../../hooks/useWindowSize'
import { getTypeHeader } from '../../../../utils/getTypeHeader'
import { headerData } from '../../DesktopPlanStart/headerData'
import Row from '../../DesktopPlanStart/Row/Row'
// import AddRow from '../../UI/AddRow/AddRow'
// import IconAccordionOpen from '../../DesktopPlanStart/IconAccordionOpen'
// import IconShevronBottom from '../../DesktopPlanStart/IconShevronBottom'

function AccordionDropdownCreate({
    items,
    isControl,
    onAddRow,
    onDeleteRow,
    onAddInnerRow,
    onDeleteInnerRow,
    onChangeFieldsInnerRow,
    onChangeFieldsRow,
    onSavePlan,
    prevDateEnd,
    isDisabledAddRow,
    typePlan
}) {
    const { width } = useWindowSize()
    const typeHeader = getTypeHeader(width)

    const getTitleColumns = () => {
        if (typeHeader === 'desctop') {
            return headerDataDesctop
        }
        if (typeHeader === 'tablet') {
            return headerDataTablet
        }
        if (typeHeader === 'mobail') {
            return headerDataInner
        }
    }

    const titleColumns = getTitleColumns()

    const handlerOnCreatePlan = () => {}

    const isEndDateGrater = (items[0].dateStart && items[0].dateEnd) ? items[0].dateStart < items[0].dateEnd : false;

    const backgroundColor = !isEndDateGrater || !items[0].dateStart || !items[0].dateEnd ? "#94A2B6" : "#2e2382";

    return (
        <div className={classes.AccordionDropdown}>
            {items?.length > 0 && (
                <>
                    <div className={classes.AccordionDropdown}>
                        <div className={classes.Header}>
                            {headerData.map((item, index) => (
                                <div className={classes.HeaderItem} key={index} dangerouslySetInnerHTML={{ __html: item }} />
                            ))}
                        </div>

                        {items.length > 0 && (
                            <div className={classes.Items}>
                                {items.map((item, index) => (
                                    <Row
                                        item={item}
                                        num={index + 1}
                                        key={item.id}
                                        isLast={items.length - 1 === index}
                                        onAddRow={onAddRow}
                                        onDeleteRow={onDeleteRow}
                                        onAddInnerRow={onAddInnerRow}
                                        onDeleteInnerRow={onDeleteInnerRow}
                                        onChangeFieldsRow={onChangeFieldsRow}
                                        onChangeFieldsInnerRow={onChangeFieldsInnerRow}
                                        prevDateEnd={prevDateEnd}
                                        isDisabledAddRow={isDisabledAddRow}
                                    />
                                ))}
                            </div>
                        )}


                        <div className={classes.AddPlanWrap}>
                            <button
                                style={{ backgroundColor }}
                                disabled={!isEndDateGrater || !items[0].dateStart || !items[0].dateEnd}
                                className={classes.AddPlan}
                                type='button'
                                onClick={() => {
                                    onSavePlan();
                                    items[0].dateStart = "";
                                    items[0].dateEnd = "";
                                }}
                            >
                                Создать
                            </button>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default AccordionDropdownCreate
