import React, { useEffect, useState, useCallback, useContext } from 'react'

import { FindButton } from '../../components/Search/FindButton/FindButton'
import { SearchModal } from '../../components/Search/SearchModal/SearchModal'
import { apiCheckTaskId } from '../../api/search'

import classes from './Search.module.scss'
import { convertTaskToRows } from './utils'
import IconPositive from '../../containers/Analise/icons/IconPositive'
import IconNegative from '../../containers/Analise/icons/IconNegative'
import IconNeutral from '../../containers/Analise/icons/IconNeutral'
import IconPending from '../../containers/Analise/icons/IconPending'

import { headers } from './constants'
import axios from 'axios'
import { useInterval } from '../../hooks/useInterval'
import { saveAs } from 'file-saver'
import * as XLSX from 'xlsx'
import Row from './Row'
import config from '../../config/config'
import { PopupContext } from '../../context/popup/popupContext'

export const tonality = [
    { name: <IconNeutral />, id: 'neutral', count: 1 },
    { name: <IconNegative />, id: 'negative', count: 1 },
    { name: <IconPositive />, id: 'positive', count: 1 },
    { name: <IconPending />, id: 'pending', count: 1 }
]


const TEST = [{
    'host': 'ru.wikipedia.org',
    'url': 'https://ru.wikipedia.org/wiki/%D0%9A%D0%BE%D1%84%D0%B5',
    'scale': { 'scale5': 1, 'scale10': 1 },
    'rating': '-',
    'header': 'Кофе — Википедия',
    'description': '',
    'tonality': 'neutral'
}, {
    'host': 'ozon.by',
    'url': 'https://ozon.by/category/kofe-9372/',
    'scale': { 'scale5': 1, 'scale10': 1 },
    'rating': '-',
    'header': 'Кофе — купить кофе в интернет-магазине OZON по низкой цене',
    'description': 'Кофе в капсулах Nescafe DOLCE GUSTO CAPPUCCINO Капучино 32 капсул (16х2). Капсульный кофе неспрессо / кофе в капсулах nespresso Aromacup Набор № 5 60 шт.',
    'tonality': 'neutral'
}, {
    'host': 'edostavka.by',
    'url': 'https://edostavka.by/category/5051',
    'scale': { 'scale5': 1, 'scale10': 1 },
    'rating': '-',
    'header': 'Кофе зерновой купить в Минске недорого - Едоставка',
    'description': '',
    'tonality': 'neutral'
}, {
    'host': 'www.wildberries.by',
    'url': 'https://www.wildberries.by/catalog?search=%D0%BA%D0%BE%D1%84%D0%B5+%D0%B2+%D0%B7%D0%B5%D1%80%D0%BD%D0%B0%D1%85',
    'scale': { 'scale5': 1, 'scale10': 1 },
    'rating': '-',
    'header': 'Wildberries — интернет-магазин модной одежды, обуви...',
    'description': 'Завтра. Ocean City Coffee Кофе в зернах 1 кг арабика 100% Brazil Original. Быстрый просмотр. Jardin Кофе в зернах Жардин Crema 1кг.',
    'tonality': 'neutral'
}, {
    'host': 'spiritus.by',
    'url': 'https://spiritus.by/',
    'scale': { 'scale5': 1, 'scale10': 1 },
    'rating': '-',
    'header': 'Spiritus Coffee: спешелти кофе с доставкой',
    'description': 'В 2013 году я пожарил кофе на кухне. Я благодарен череде событий, которые когда-то меня привели в это дело. С тех пор я не могу остановиться в совершенствовании этого процесса и себя в нем.',
    'tonality': 'neutral'
}, {
    'host': 's9coffee.by',
    'url': 'https://s9coffee.by/catalog/svezheobzharennyy/',
    'scale': { 'scale5': 1, 'scale10': 1 },
    'rating': '-',
    'header': 'Свежеобжаренный кофе в зернах и молотый купить в Минске...',
    'description': '',
    'tonality': 'neutral'
}, {
    'host': 'singlestep.by',
    'url': 'https://singlestep.by/',
    'scale': { 'scale5': 1, 'scale10': 1 },
    'rating': '-',
    'header': 'Свежеобжаренный кофе Single Step Coffee Co.',
    'description': 'Результатом стал сочный и сбалансированный кофе со сладким фруктовым профилем и оттенками красных ягод, яблока, искристостью граната и сладостью молочного шоколада.',
    'tonality': 'neutral'
}, {
    'host': 'ilovecoffee.by',
    'url': 'https://ilovecoffee.by/',
    'scale': { 'scale5': 1, 'scale10': 1 },
    'rating': '-',
    'header': 'I Love Coffee - Купить кофе в Минске с доставкой, цены',
    'description': 'За последние 15 лет я перепробовал множество моно-сортов и смесей различных производителей и с уверенностью могу сказать, что качественный и вкусный кофе бывает не только дорогим, но и бюджетным.',
    'tonality': 'neutral'
}, {
    'host': 'tr-page.yandex.ru',
    'url': 'https://tr-page.yandex.ru/translate?lang=en-ru&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FRegular_coffee',
    'scale': { 'scale5': 1, 'scale10': 1 },
    'rating': '-',
    'header': 'Кофе - Википедия',
    'description': 'Кофе темного цвета, горький, слабокислый и оказывает стимулирующее действие на человека, в первую очередь благодаря содержанию в нем кофеина.[3] Это один из самых популярных напитков в мире[4], который может быть приготовлен и представлен различными способами (например, эспрессо, френч-пресс, кофе латте или уже сваренный...',
    'tonality': 'neutral'
}, {
    'host': 'eda.yandex.by',
    'url': 'https://eda.yandex.by/minsk/d/coffee',
    'scale': { 'scale5': 1, 'scale10': 1 },
    'rating': '-',
    'header': 'Кофе — заказать доставку от 30 минут в Минске',
    'description': 'Кофе — заказать в сервисе Яндекс Еда с доставкой в Минске от 30 минут. В офис или на дом. Первая доставка из ресторанов бесплатно.',
    'tonality': 'neutral'
}]

const Search = () => {
    const [result, setResult] = useState([])
    const [isShowFindModal, setIsShowFindModal] = useState(false)
    const [taskId, setTaskId] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)
    const [progress, setProgress] = useState(0)

    const [isXlsxChecked, setIsXlsxChecked] = useState(false)
    const [isCsvChecked, setIsCsvChecked] = useState(false)

    const [brandName, setBrandName] = useState('')
    const [findSystem, setFindSystem] = useState(1)
    const [region, setRegion] = useState('')
    const [regionId, setRegionId] = useState(null)
    const [deep, setDeep] = useState(10)
    const [suggestions, setSuggestions] = useState([])

    const { setPreloader } = useContext(PopupContext);


    const handleXlsxChange = (e) => {
        setIsXlsxChecked(e.target.checked)
    }

    const handleCsvChange = (e) => {
        setIsCsvChecked(e.target.checked)
    }

    const isButtonDisabled = !isXlsxChecked && !isCsvChecked


    const getTaskInfo = useCallback(async () => {
        if (isLoading) return
        setError(null)
        setIsLoading(true)
        localStorage.setItem('taskId', JSON.stringify(taskId))

        try {

            const result = await apiCheckTaskId(taskId);

            if (result) {
                setPreloader(true);

                if (+result.progress === 100) {
                    const params = {
                        taskId,
                    };

                    const response = await axios.get(`${config.SERVER_BASE_URL}/search-third-service/search-result`, { params });

                    if (response?.status === 200) {
                        if (response?.data?.result) {
                            const data = convertTaskToRows(response?.data?.result)
                            setResult(data)
                        }

                        setPreloader(false);
                    }

                    if (response?.status === 400) {
                        if (response?.data?.message) {
                            setError(`Произошла ошибка! Создайте запрос заново. Описание ошибки: ${response.data?.message}`)
                        }

                        setPreloader(false);
                    }
                }
                setProgress(+result.progress)
            }

        } catch (error) {
            setPreloader(false);
            setError(error.message)
        } finally {
            setIsLoading(false)
            localStorage.removeItem('taskId')
        }
    })


    const handleCreateTaskId = async (id) => {
        localStorage.removeItem('taskId')
        setIsLoading(false)
        setError(null)
        setTaskId(id)

    }

    const renderTableHeader = (headers) => {
        return (
            <tr>
                {headers.map((header) => (
                    <th style={{ padding: 10, fontWeight: 'bold', texAligh: 'left' }}
                        key={`${header}-${Math.random()}`}>
                        {header}
                    </th>
                ))}
            </tr>
        )
    }
    const renderTableRow = (task, rowIndex) => {
        return (
            <Row
                result={result}
                data={task}
                rowIndex={rowIndex}
                onTapUrl={onTapUrl}
                onChangeTonality={handleChangeTonality}
                onChangeRating={handleChangeRating}
            />
        );
    };

    // const handleChangeScale5 = useCallback(
    //     (event, rowIndex) => {
    //         const value = scale5.find((item) => item.id === event).name
    //         result[rowIndex].scale.scale5 = value
    //     },
    //     [result]
    // )
    //
    // const handleChangeScale10 = useCallback(
    //     (event, rowIndex) => {
    //         const value = scale10.find((item) => item.id === event).name
    //         result[rowIndex].scale.scale10 = value
    //     },
    //     [result]
    // )

    const handleChangeRating = (block, rowIndex, scale) => {
        result[rowIndex].rating = block.name;
        result[rowIndex].scale = block.scale === "scale5" ? "5 балльная" : (block.scale === "scale10" ? "10 балльная" : "-");
    }

    const handleChangeTonality = useCallback(
        (event, rowIndex) => {
            result[rowIndex].tonality = event.target.value
        },
        [result]
    )


    const onTapUrl = (event, url) => {
        event.preventDefault()

        navigator.clipboard.writeText(url)
            .then(() => {
                console.log('URL copied to clipboard:', url)
            })
            .catch(err => {
                console.error('Could not copy text: ', err)
            })

        window.open(url, '_blank')
    }


    const saveToExcel = (data, fileName) => {
        const list = [{
            host: "Домен",
            url: "URL",
            scale: "Шкала",
            rating: "Рейтинг",
            header: "Заголовок",
            description: "Описание",
            tonality: "Тональность",
        }];

        data.forEach((x) => {
            list.push({
                host: x.host,
                url: x.url,
                scale: typeof x.scale === "string" ? x.scale : "-",
                rating: x.rating,
                header: x.header,
                description: x.description,
                tonality: x.tonality,
            });
        });

        const worksheet = XLSX.utils.json_to_sheet(list, { skipHeader: true })
        const workbook = XLSX.utils.book_new();
        worksheet['!cols'] = [{wpx: 100}, {wpx: 100}, {wpx: 70}, {wpx: 70}, {wpx: 100}, {wpx: 100}, {wpx: 100}];
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' })
        saveAs(blob, `${fileName}.xlsx`)
    }


    const handleSave = () => {
        if (isXlsxChecked) {
            const top30 = result.length > 30 ? result.slice(0, 30) : result
            saveToExcel(top30, 'result')
        }
        if (isCsvChecked) {
            saveToCSV()
        }
    }
    const saveToCSV = () => {
        const top30 = result.length > 30 ? result.slice(0, 30) : result

        let value = ''
        value += 'Домен,URL,Шкала,Рейтинг,Заголовок,Описание,Тональность\n'
        top30.forEach((x) => {
            value += `"${x.host}",`
            value += `"${x.url}",`
            value += `"${typeof x.scale === "string" ? x.scale : "-"}",`
            value += `"${x.rating}",`
            value += `"${x.header}",`
            value += `"${x.description}",`
            value += `"${x.tonality}"\n`
        })
        const blob = new Blob([value], { type: 'text/csv' })
        window.open(URL.createObjectURL(blob))
    }

    useInterval(
        async () => {
            await getTaskInfo(taskId)
        },
        taskId != null && progress < 100 ? 6000 : null
    )

    useEffect(() => {
        const storageTaskId = localStorage.getItem('taskId')
        if (storageTaskId) {
            setTaskId(storageTaskId)
            setProgress(100)
            getTaskInfo()
        }
    }, [])


    const showModal = () => {
        setProgress(0)
        setBrandName('')
        setTaskId(null)
        setSuggestions([])
        setResult([])
        setRegion('')
        setRegionId(null)
        setIsShowFindModal(true)
        setIsXlsxChecked(false)
        setIsCsvChecked(false)
    }

    return (
        <div className={`replic ${classes.Desktop}`}>
            <FindButton onClick={showModal} />
            {!isShowFindModal && (
                <div className={classes.DesktopWrapper}>
                    <h1 className={classes.Title}>Поиск {taskId ? `${progress}%` : ''}</h1>
                    {(!isLoading && error) && <h1 className={classes.loadingTitle}>{error}</h1>}
                    {!isLoading && result.length > 0 ? (
                        <div className={classes.TableWrapper}>
                            <table style={{ width: '100%' }}>
                                <thead className={classes.TableHead}>{headers && renderTableHeader(headers)}</thead>
                                <tbody>{result.map((task, rowIndex) => renderTableRow(task, rowIndex))}</tbody>
                            </table>
                        </div>
                    ) : null}

                    {!isLoading && result.length > 0 && <div onClick={() => setIsXlsxChecked(prev => !prev)} style={{ marginTop: 24, cursor: 'pointer', width: 180, paddingTop: 12, paddingBottom: 12  }}>
                        <input
                            type='checkbox'
                            id='xlsx'
                            checked={isXlsxChecked}
                            style={{ cursor: 'pointer' }}
                            onChange={handleXlsxChange}
                        />
                        <label htmlFor='xlsx' style={{ marginLeft: 14, cursor: 'pointer', }}>Сохранить как XLSX</label>
                    </div>}
                    {!isLoading && result.length > 0 && <div onClick={() => setIsCsvChecked(prev => !prev)} style={{ marginTop: 24, cursor: 'pointer', width: 180, paddingTop: 12, paddingBottom: 12 }}>
                        <input
                            type='checkbox'
                            id='csv'
                            checked={isCsvChecked}
                            style={{ cursor: 'pointer' }}
                            onChange={handleCsvChange}
                        />
                        <label htmlFor='csv' style={{ marginLeft: 14, cursor: 'pointer' }}>Сохранить как CSV</label>
                    </div>}
                    {!!result.length && !isLoading && (
                        <button
                            disabled={isButtonDisabled}
                            onClick={handleSave} style={{
                            marginTop: 34,
                            backgroundColor: isButtonDisabled ? 'gray' : '#e00c3c',
                            color: '#fff'
                        }}>
                            Сохранить
                        </button>
                    )}
                </div>
            )}


            <SearchModal
                deep={deep}
                brandName={brandName}
                regionId={regionId}
                region={region}
                findSystem={findSystem}
                isVisible={isShowFindModal}
                suggestions={suggestions}
                setTaskId={handleCreateTaskId}
                onClose={() => setIsShowFindModal(false)} onRegionName={setRegion} onRegionId={setRegionId}
                onBrandName={setBrandName}
                onSuggestions={setSuggestions}
                onFindSystem={setFindSystem}
                onDeep={setDeep} />
        </div>
    )
}

export default Search
